<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Products">
                <delete-items-button v-if="$hasPermission($permissions.RemoveProducts)" :selection="selection" @deleted="loadData" @reset="selection.selected_item_ids = []"/>

                <b-button v-if="$hasPermission($permissions.AddProducts)" variant="primary" @click="$refs.addModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
                <basic-table ref="basicTable"  :columns="columns" :data="formattedData" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <template  name="table-row">
                        <div v-if="props.column.displayType === 4 && $hasPermission($permissions.RemoveProducts)" class="d-flex justify-content-center">
                            <b-form-checkbox  v-model="selection.selected_item_ids" :value="products[props.row.index].id"></b-form-checkbox>
                        </div>
                        <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                            <b-button v-if="$hasPermission($permissions.EditProducts)" variant="warning" @click="$refs.editModal.open(products[props.row.index])" class="mr-1">
                                <feather-icon icon="EditIcon"/>
                            </b-button>
                            <b-button v-if="$hasPermission($permissions.RemoveProducts)" variant="danger" @click="removeProduct(products[props.row.index].id)" class="mr-1">
                                <feather-icon icon="Trash2Icon"/>
                            </b-button>
                        </div>
                        <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                            <div class="rounded-sm mr-1" :style="{width: '60px', height: '25px', backgroundColor: props.formattedRow[props.column.field] }"></div>
                            {{ props.formattedRow[props.column.field] }}
                        </div>

                        <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                            <b-badge v-if="props.row.active === true" pill variant="success">Active</b-badge>
                            <b-badge v-else-if="props.row.active === false" pill variant="warning">Not active</b-badge>

                        </div>
                        <span v-else class="d-flex justify-content-center">
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                </basic-table>
            </b-card>

            <AddProductModal ref="addModal" :categories="categories" v-on:itemAdded="loadData"/>

            <EditProductModal ref="editModal" :categories="categories" v-on:itemSaved="loadData"/>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay, BBadge, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddProductModal from '@/views/Product/AddProductModal'
    import EditProductModal from '@/views/Product/EditProductModal'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            EditProductModal,
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BBadge,
            AddProductModal,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                products: [],
                categories: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },

                columns: [
                    {
                        label: 'Select',
                        displayType: 4,
                        field: 'select',
                        sortable: false


                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Color',
                        displayType: 2,
                        field: 'color',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Brand',
                        displayType: 0,
                        field: 'brand',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Category',
                        displayType: 0,
                        field: 'category',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'SKU',
                        displayType: 0,
                        field: 'sku',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Unit',
                        displayType: 0,
                        field: 'unit',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Quantity multiplier',
                        displayType: 0,
                        field: 'quantity_multiplier',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Price without VAT',
                        displayType: 0,
                        field: 'price_without_vat',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'VAT Percentage',
                        displayType: 0,
                        field: 'vat_percentage',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'VAT',
                        displayType: 0,
                        field: 'vat',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Active',
                        displayType: 3,
                        field: 'active',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/product')
                loadPromise.then(function(response) {
                    thisIns.products = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const categoryLoadPromise = this.$http.get('/api/management/v1/product_category')
                categoryLoadPromise.then(function(response) {
                    thisIns.categories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, categoryLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            async removeProduct(id) {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to delete this product?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/product/${  id}`)
                    this.$printSuccess('Product removed')
                    this.loadData()
                    
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                }
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }

        },
        computed: {
            formattedData() {
                return this.products.map((product, index) => {
                    return {
                        index,
                        id:product.id,
                        color: product.color,
                        name: product.name,
                        brand: product.brand,
                        unit: product.unit,
                        quantity_multiplier: product.quantity_multiplier,
                        category: (product.category.length > 0) ? product.category : 'Unknown',
                        sku: product.sku,
                        price_without_vat: (product.price_without_vat / 100).toFixed(2),
                        vat: (product.vat / 100).toFixed(2),
                        vat_percentage: `${Number(((product.vat / product.price_without_vat) * 100).toFixed(1)).toFixed(2)  }%`,
                        active: product.active
                    }
                })
            }
        },
        mounted() {
            this.selection.collection_name = 'products'
            this.loadData()
        }
    }
</script>