<template>
    <b-modal title="Save product" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <b-form-checkbox v-model="editObject.active">Active</b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <label>Name</label>
                <b-form-input v-model="editObject.name"/>
            </b-form-group>
            <b-form-group>
                <label>Category</label>
                <v-select
                    v-model="editObject.category_id"
                    :reduce="item => item.id"
                    label="name"
                    :options="categories"
                />
            </b-form-group>
            <b-form-group>
                <label>Brand</label>
                <b-form-input v-model="editObject.brand"/>
            </b-form-group>
            <b-form-group>
                <label>Unit</label>
                <b-form-input v-model="editObject.unit"/>
            </b-form-group>
            <b-form-group>
                <label>Quantity multiplier</label>
                <b-form-input :value="editObject.quantity_multiplier" v-on:input="(value) => editObject.quantity_multiplier = Number(value)"/>
            </b-form-group>
            <b-form-group>
                <label>SKU</label>
                <b-form-input v-model="editObject.sku"/>
            </b-form-group>
            <b-form-group>
                <label>Price without VAT</label>
                <b-form-input :value="displayedPrice" v-on:input="changePriceWithoutVAT"/>
            </b-form-group>
            <b-form-group>
                <label>VAT Percentage</label>
                <b-form-input :value="vatPercentage" v-on:input="changeVATPercentage"/>
            </b-form-group>
            <b-form-group>
                <label>VAT Value</label>
                <b-form-input :value="displayedVATValue" v-on:input="changeVATValue"/>
            </b-form-group>

            <ColorPicker v-model="editObject.color"/>

        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="modalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="saveProduct">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox} from 'bootstrap-vue'
    import ColorPicker from '@/views/components/ColorPicker'
    import vSelect from 'vue-select'

    export default {
        props: {
            categories: {
                type: Array,
                required: true
            }
        },
        components: {
            ColorPicker,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckbox,
            vSelect
        },
        data() {
            return {

                modalActive: false,
                editObject: {
                    color: '#000000',
                    name: '',
                    brand: '',
                    category_id: '',
                    sku: '',
                    unit: '',
                    quantity_multiplier: 1,
                    price_without_vat: 0,
                    vat: 0,
                    active: true
                },

                displayedPrice: 0,
                displayedVATValue: 0,
                vatPercentage: 0

            }
        },
        methods: {
            open(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.displayedPrice = this.editObject.price_without_vat / 100
                this.displayedVATValue = this.editObject.vat / 100
                this.vatPercentage = Number(((this.editObject.vat / this.editObject.price_without_vat) * 100).toFixed(1)).toFixed(2)
                this.modalActive = true
            },
            saveProduct() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/product/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.modalActive = false
                    thisIns.$emit('itemSaved')
                    thisIns.$printSuccess('Product saved')
                    thisIns.editObject = {
                        color: '#000000',
                        name: '',
                        brand: '',
                        category: thisIns.editObject.category,
                        sku: '',
                        unit: '',
                        quantity_multiplier: 1,
                        price_without_vat: 0,
                        vat: 0
                    }

                    thisIns.displayedPrice = 0
                    thisIns.displayedVATValue = 0
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            changePriceWithoutVAT(val) {
                this.displayedPrice = val
                this.editObject.price_without_vat = Number(Number(val * 100).toFixed(0))
                this.editObject.vat = Number((Number(this.vatPercentage / 100) * this.editObject.price_without_vat).toFixed(0))
                this.displayedVATValue = (this.editObject.vat / 100).toFixed(2)
            },
            changeVATValue(val) {
                this.displayedVATValue = val
                this.editObject.vat = Number(Number(this.displayedVATValue * 100).toFixed(0))
                this.vatPercentage = ((this.editObject.vat / this.editObject.price_without_vat) * 100).toFixed(2)
            },
            changeVATPercentage(val) {
                this.vatPercentage = val
                this.editObject.vat = Number(((Number(this.vatPercentage) / 100) * this.editObject.price_without_vat).toFixed(0))
                this.displayedVATValue = (this.editObject.vat / 100).toFixed(2)
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>